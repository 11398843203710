import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import '../assets/SASS/footer.scss';
import gnaLogo from '../assets/images/gnalogowhite.png';

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000); // Update every minute to catch new year changes

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="lg:h-footer-height pb-4 lg:pb-0">
      <div className="lg:w-9/12 w-full h-5/6 flex md:flex-row flex-col mx-auto text-white ">
        <div className="lg:w-5/12 md:w-1/2 px-6 mt-16">
          <div>
            <img className="w-48" src={gnaLogo} alt="" />
          </div>
          <p className="mt-2 opacity-80">
            We have the capability to handle shipments of any size and complexity.
            Our commitment to excellence and customer satisfaction sets us
            apart as a leader in the logistics industry in Ghana.
          </p>
          <div className="lg:flex hidden mt-4 items-center">
            <div className="flex flex-col">
              <h4 className="text-sm font-light">Make a Call </h4>
              <div className="flex gap-3">
                <div>
                  <i className="fa-solid fa-phone fa-xl text-white"></i>
                </div>
                <div>
                  <p className=" text-sm font-semibold ">+44 38 359 7444</p>
                  <p className=" text-sm font-semibold ">+233 (0)55 448 7416</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-3/12 md:w-1/2 px-6 mt-16">
          <div className="border-b-2 pb-1">Contact Us</div>
          <ul className="opacity-80 mt-2 flex flex-col gap-1">
            <li className="flex gap-2">
              <i className="fa-solid mt-1 fa-phone text-white"></i>
              <div>
                <p>+44 38 359 7444</p>
                <p>+233 (0)59 568 9613</p>
              </div>
            </li>
            <li className="flex gap-2">
              <i className="fa-solid mt-1 text-white fa-envelope"></i>
              <p>info@gnaprobiz.com</p>
            </li>
            <li className="flex gap-2">
              <i className="fa-solid mt-1 text-white fa-location-dot"></i>
              <p className="text-sm">Terema Street, opposite 2927 restaurant. Tesano - Accra, Ghana</p>
            </li>
            <li className="flex gap-2">
              <i className="fa-solid mt-1 text-white fa-location-dot"></i>
              <p className="text-sm">Acid Street, near ICGC TEMPLE Abease Amasaman - Accra, Ghana</p>
            </li>
          </ul>
        </div>
        <div className="w-2/12 hidden lg:flex lg:flex-col px-3 mt-16">
          <div className="border-b-2 pb-1">Quick Links</div>
          <ul className="opacity-80 mt-2 list-none">
            <li className="relative">
              <ScrollLink
                to="home"
                smooth={true}
                duration={500}
                className="arrow cursor-pointer"
              >
                Home
              </ScrollLink>
            </li>
            <li className="relative">
              <ScrollLink
                to="aboutus"
                smooth={true}
                duration={500}
                className="arrow cursor-pointer"
              >
                About Us
              </ScrollLink>
            </li>
            <li className="relative">
              <ScrollLink
                to="aboutus"
                smooth={true}
                duration={500}
                className="arrow cursor-pointer"
              >
                About Us
              </ScrollLink>
            </li>
            <li className="relative">
              <ScrollLink
                to="blog"
                smooth={true}
                duration={500}
                className="arrow cursor-pointer"
              >
                Blog
              </ScrollLink>
            </li>
            <li className="relative">
              <ScrollLink
                to="contact-us"
                smooth={true}
                duration={500}
                className="arrow cursor-pointer"
              >
                Contact Us
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="w-2/12 hidden lg:flex lg:flex-col px-3 mt-16">
          <div className="border-b-2 pb-1">Services</div>
          <ul className="opacity-80 mt-2 list-none">
            <li className="relative">
              <span className="arrow">Air Freight - D2D/A2A</span>
            </li>
            <li className="relative">
              <span className="arrow">Sea Freight - FCL/LCL</span>
            </li>
            <li className="relative">
              <span className="arrow">China to Ghana</span>
            </li>
            <li className="relative">
              <span className="arrow">Cargo haulage</span>
            </li>
            <li className="relative">
              <span className="arrow">Cargo Clearance</span>
            </li>
            <li className="relative">
              <span className="arrow">Procurement</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex lg:mt-0 mt-6 md:flex-row flex-col px-3 w-9/12 lg:h-1/6 justify-between items-center mx-auto text-white">
        <div>
          <p className=" text-xs">GNA Probiz Enterprise &copy; {currentYear}</p>
        </div>
        <div className="flex my-6 gap-3">
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-x-twitter fa-2xl"></i>
          </a>
          <a href="https://wa.me/233554487416" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-2xl fa-whatsapp"></i>
          </a>
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram fa-2xl"></i>
          </a>
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook fa-2xl"></i>
          </a>
        </div>
      </div>
      <a href="https://wa.me/233554487416" className="whatsapp-icon" target="_blank" rel="noopener noreferrer">
        <i className="fa-brands fa-xl fa-whatsapp"></i>
      </a>
    </footer>
  );
}

export default Footer;
