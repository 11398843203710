import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Quote = () => {
  const form = useRef();
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm('service_900uydm', 'template_5konxmg', form.current, {
        publicKey: 'HsG-TZeruhztSss5Y',
      })
      .then(
        () => {
          setPopupVisible(true);
          setPopupContent('SUCCESS!');
        },
        (error) => {
          setPopupVisible(true);
          setPopupContent(`FAILED. TRY AGAIN LATER: ${error.text}`);
        }
      )
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setPopupVisible(false);
          setPopupContent('');
        }, 3000); // Hide popup after 3 seconds
      });
  };

  return (
    <section className="bg-my-gray py-24 w-full">
      <div className="w-9/12 mx-auto rounded-lg shadow-lg bg-white p-10">
        <h2 className="font-bold text-lg">REQUEST A QUOTE</h2>
        <form className="mt-3 grid grid-cols-4 gap-4" ref={form} onSubmit={sendEmail}>
          <div className="col-span-4 sm:col-span-2">
            <label className="hidden">Name</label>
            <input className="w-full rounded-lg bg-my-gray px-3 py-1" required type="text" placeholder="Name" name="to_name" />
          </div>
          <div className="col-span-4 sm:col-span-2">
            <label className="hidden">Email</label>
            <input className="w-full rounded-lg bg-my-gray px-3 py-1"  required placeholder="email" type="email" name="from_name" />
          </div>

          <div className="col-span-4 sm:col-span-2">
            <label className="hidden" htmlFor="phone">Phone Number:</label>
            <input className="w-full rounded-lg bg-my-gray px-3 py-1" type="tel" name="from_number" placeholder="Phone number" />
          </div>

          <div className="col-span-4 md:col-span-2">
            <label className="hidden" htmlFor="freightType">Freight Type:</label>
            <select id="freightType" name="freightType" className="w-full rounded-lg bg-my-gray px-3 py-1">
              <option value="Sea & Air Freighting">Sea & Air Freighting</option>
              <option value="Warehousing Services">Warehousing Services</option>
              <option value="Logistics Planning">Logistics Planning</option>
              <option value="Road Transportation">Road Transportation</option>
              <option value="Courier">Courier</option>
            </select>
          </div>

          <div className="col-span-4">
            <label className="hidden">Message</label>
            <textarea className="w-full rounded-lg bg-my-gray p-2" placeholder="Message" rows="4" required name="message" />
          </div>

          <div className="w-fit">
            <button className="mt-1 bg-my-orange text-white px-3 py-1.5 rounded-md w-full" type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
          </button>
          </div>
        </form>
      </div>
      {popupVisible && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            {popupContent}
          </div>
        </div>
      )}
    </section>
  );
};

export default Quote;




    // <section className="bg-my-gray py-24 w-full">
    //        <div className="col-span-4">
    //          <textarea id="message" name="message" className="w-full rounded-lg bg-my-gray p-2" placeholder="Message" rows="4" required></textarea>
    //        </div>
    //     </form>
    //   </div>
    // </section>

// import React, { useState } from 'react';
// import axios from 'axios';

// const Quote = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     dimensions: '',
//     weight: '',
//     freightType: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post('http://localhost:3000/send-email', formData);
//       alert('Email sent successfully!');
//       setFormData({
//         name: '',
//         email: '',
//         phone: '',
//         dimensions: '',
//         weight: '',
//         freightType: '',
//         message: ''
//       });
//     } catch (error) {
//       console.error('Failed to send email:', error);
//       alert('Failed to send email. Please try again later.');
//     }
//   };

//   return (
//     <section className="bg-my-gray py-24 w-full">
//       <div className="w-9/12 mx-auto rounded-lg shadow-lg bg-white p-10">
//         <h2 className="font-bold text-lg">REQUEST A QUOTE</h2>
//         <form className="mt-3 grid grid-cols-4 gap-4" onSubmit={handleSubmit}>
//           <div className="col-span-4 sm:col-span-2">
//              <label className="hidden" htmlFor="firstName"> Name:</label>
//              <input type="text" id="firstName" className="w-full rounded-lg bg-my-gray px-3 py-1" name="Name" placeholder="Name" required />
//            </div>
//            <div className="col-span-4 sm:col-span-2">
//              <label className="hidden" htmlFor="email">Email:</label>
//              <input type="email" placeholder="Email" id="email" name="email" className="w-full rounded-lg bg-my-gray px-3 py-1"  required />
//            </div>
//            <div className="col-span-4 sm:col-span-2">
//              <label className="hidden" htmlFor="phone">Phone Number:</label>
//              <input type="tel" id="phone" name="phone" placeholder="Phone Number" className="w-full rounded-lg bg-my-gray px-3 py-1"  required />
//            </div>
//            <div className="col-span-4 sm:col-span-2">
//              <label className="hidden" htmlFor="dimensions">Dimensions:</label>
//              <input type="text" id="dimensions" name="dimensions" placeholder="Dimensions" className="w-full rounded-lg bg-my-gray px-3 py-1" />
//            </div>
//            <div className="col-span-4 sm:col-span-2">
//              <label className="hidden" htmlFor="weight">Weight:</label>
//              <div className="flex">
//                <input type="text" id="weight" name="weight" placeholder="Weight" className="w-full rounded-lg bg-my-gray px-3 py-1" />
//                <select className="ml-2 bg-my-gray px-3 py-1 rounded-lg">
//                  <option value="kg">kg</option>
//                  <option value="lb">lb</option>
//                </select>
//              </div>
//            </div>
//            <div className="col-span-4 md:col-span-2">
//              <label className="hidden" htmlFor="freightType">Freight Type:</label>
//              <select id="freightType" name="freightType" className="w-full rounded-lg bg-my-gray px-3 py-1">
//                <option value="Sea & Air Freighting">Sea & Air Freighting</option>
//                <option value="Warehousing Services">Warehousing Services</option>
//                <option value="Logistics Planning">Logistics Planning</option>
//                <option value="Road Transportation">Road Transportation</option>
//                <option value="Courier">Courier</option>
//              </select>
//            </div>
//            <div className="col-span-4">
//              <textarea id="message" name="message" className="w-full rounded-lg bg-my-gray p-2" placeholder="Message" rows="4" required></textarea>
//            </div>
//            <div className="w-fit">
//              <button className="mt-1 bg-my-orange text-white px-3 py-1.5 rounded-md w-full" type="submit">Submit</button>
//            </div>
//         </form>
//       </div>
//     </section>
//   );
// };

// export default Quote;
   