import React from 'react';
import Quote from './Quote';
import Footer from './Footer';
import '../assets/SASS/services.scss';
import plane from '../assets/images/freight.jpg';
import ghana from '../assets/images/chinaGhana.jpg'; 
import ship from '../assets/images/banner.jpg';
import warehousing from '../assets/images/gallery/warehouse.jpg';
import customs from '../assets/images/seaportTwo.jpg';
import logistics from '../assets/images/gallery/planning.jpg';
import FAQ from './FAQ';

const Services = () => {
  return (
    <>
      <div className="pt-16 services h-service-height flex flex-col justify-center">
        <div className="w-9/12 mx-auto text-white">
          <h1 className="text-4xl">Services</h1>
          <p>
            Explore our comprehensive range of logistics services, including Air Freight - D2D/A2A, Sea Freight - FCL/LCL, road
            <br />
            transportation, Cargo haulage, and customs clearance, tailored to meet your unique needs.
          </p>
        </div>
      </div>
      <section className="bg-my-gray pb-16 pt-16">
        <p className="flex justify-center text-my-orange font-bold">What We Do</p>
        <div className="flex flex-col w-9/12 mx-auto">
          <h1 className="flex justify-center text-center mt-4 text-4xl font-bold">
            Specialist Logistics Servives
          </h1>
          <p className="flex justify-center text-center mt-3">
            Customized solutions for complex logistics challenges. These services are tailored to meet specific industry needs, offering
            <br />
            expertise in areas such as Air Freight - D2D/A2A, Sea Freight - FCL/LCL, Cargo haulage, Procurement.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-4 mt-8 w-9/12 mx-auto">
        <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className="" src={plane} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  Air Freight - D2D/A2A
                </h1>
                <p className="px-1 pt-2 text-sm">
                  Send and receive parcels of all weights/volumes door-to-door by air in just a few days. Ship to locations across the world in 5 days; and receive packages from suppliers, clients, and loved ones seamlessly.
                  <br />
                  <span className="text-my-orange text-xs font-bold">&bull; D2D (Door-to-door)</span>
                  <br />
                  <span className="text-my-orange text-xs font-bold">&bull; A2A (Airport-to-airport)</span>
                </p>
            </div>
          </div>
          <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className=" -translate-y-6" src={ship} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  Sea Freight - FCL/LCL
                </h1>
                <p className="px-1 pt-2 text-sm">
                  Ship goods by sea from suppliers abroad at budget-friendly rates; and fulfill large orders from clients abroad without hassle 20', 40', HC, and refrigerated containers across any two seaports on the globe.
                  <br />
                  <span className="text-my-orange text-xs font-bold">&bull; FCL (Full-container-load)</span>
                  <br />
                  <span className="text-my-orange text-xs font-bold">&bull; LCL (Less-than-full-container-load)</span>
                </p>
            </div>
          </div>         
          <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className=" -translate-y-7" src={ghana} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  China to Ghana
                </h1>
                <p className="px-1 pt-2 text-sm">
                  We ship cargo from China to Ghana - sea freight and air freight - several times each month at competitive rates and unbeatable transit days.
                </p>
            </div>
          </div>         
          <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className="" src={warehousing} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  Cargo haulage
                </h1>
                <p className="px-1 pt-2 text-sm">
                  We have several storage facilities across the country to store your cargo in the best conditions.
                </p>
            </div>
          </div>       
          <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className=" -translate-y-56
                  " src={customs} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  Cargo Clearance
                </h1>
                <p className="px-1 pt-2 text-sm">
                  We handle your cargo clearance with integrity, efficiency, and urgency; for both your air and sea imports. Air cargo clearance at KIA (ACC) Sea cargo clearance at Tema Port.
                </p>
            </div>
          </div>       
          <div className="bg-white h-card-height rounded-3xl shadow-xl">
            <div className="plane overflow-hidden rounded-ss-3xl rounded-se-3xl h-2/6 pt-3 px-3 bg-slate-50">
                <div className=" rounded-ss-xl rounded-se-xl overflow-hidden">
                  <img className="" src={logistics} alt="" srcset="" />
                </div>
            </div>
            <div className="pt-3 px-3">
                <h1 className=" font-semibold justify-end text-my-orange">
                  Procurement
                </h1>
                <p className="px-1 pt-2 text-sm">
                  We procure all kinds of goods from Ghana, China, Turkey, UAE, UK, and USA. We source, order, pay, and deliver.
                </p>
            </div>
          </div>
        </div>
      </section>
      <FAQ />
      <Quote />
      <Footer />
    </>
  );
};

export default Services;
